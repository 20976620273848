import { globalCheckboxPreset } from "~/components/abstracts/Checkbox/utils/global-checkbox-preset"
import { formKeys } from "~/providers/I18nProvider/constants"

export const checkboxPresets = {
  [globalCheckboxPreset.acceptTerms]: {
    required: true,
    name: globalCheckboxPreset.acceptTerms,
    id: globalCheckboxPreset.acceptTerms,
    label: formKeys.privacy_policy_label,
  },
}

export type CheckboxPresets = keyof typeof checkboxPresets
