"use client"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import type { PropsWithClassName } from "~/@types/generics"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { objectKeys } from "~/utils/object-keys"

export type AbstractErrorListProps = PropsWithClassName<{
  name?: string
  id?: string
}>

function AbstractErrorList({ name, className, id }: AbstractErrorListProps) {
  const t = useTranslate()
  const { formState } = useFormContext()

  const error = name ? formState?.errors?.[name] ?? null : null

  const hasErrorType = error?.type
  const hasErrorTypes = (error?.types && objectKeys(error?.types)?.length > 0) as boolean
  const hasErrors = hasErrorType || hasErrorTypes

  const divProps = {
    className: clsx(className),
    ...(id ? { id } : {}),
  }

  return hasErrors ? (
    <div data-comp={"Abstract/ErrorList"} {...divProps}>
      {hasErrorType && <div>{t(`error_${error?.type}`)}</div>}
      {error?.types &&
        objectKeys(error?.types)?.map((err: string, index) => <div key={`${err}${index}`}>{t(`error_${err}`)}</div>)}
    </div>
  ) : null
}

export default AbstractErrorList
