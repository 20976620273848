import type { UseMutationResult } from "@tanstack/react-query"
import { useForm, type FieldValues, type UseFormProps } from "react-hook-form"

import { ALERT_CODES } from "~/lib/alert"
import { RECAPTCHA_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { useAlerts } from "~/hooks/useAlerts"
import { useGoogleReCaptcha } from "~/providers/RecaptchaProvider/use-google-recaptcha"
import { logger } from "~/utils/logger"
import type { VerifiyResponse } from "~/app/api/verify-recaptcha/route"

type TAction = "newsletter" | "contact"

export const useFormWithMutation = <T extends FieldValues>(
  mutation: UseMutationResult<unknown, unknown, T>["mutate"],
  formOptions?: UseFormProps<T>,
  action?: TAction
) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const triggerAlert = useAlerts()
  const form = useForm<T>({
    ...formOptions,
  })

  const submitHandler = form.handleSubmit(async (data) => {
    let handleCaptcha
    if (!action) return mutation(data)
    try {
      if (!executeRecaptcha) {
        triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
        return
      }

      const token = await executeRecaptcha(action)
      handleCaptcha = (await requestNextApiEndpoint(RECAPTCHA_PARAMS, {
        payload: { token, action },
      })) as Partial<VerifiyResponse>

      if (!handleCaptcha?.success) {
        triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
        return
      }

      mutation(data)
    } catch (err) {
      logger.error("Recaptcha Error", err)
      triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
    }
  })

  return { form, onSubmit: submitHandler }
}
