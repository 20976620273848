import { useId, useState, type CSSProperties, type ReactNode } from "react"
import clsx from "clsx"
import type { FieldErrors } from "react-hook-form"

import Input, { type AbstractInputProps } from "~/components/abstracts/Input"

interface AbstractCheckboxProps extends AbstractInputProps {
  className?: string
  inputClassName?: string
  contentClassName?: string
  labelClassName?: string
  checkmarkClassName?: string
  checkmarkActiveClassName?: string
  checkmarkActive?: ReactNode
  checkmarkStyle?: CSSProperties
  label?: ReactNode
  id?: string
}

function AbstractCheckbox({
  className,
  withError,
  inputClassName,
  contentClassName,
  labelClassName,
  checkmarkClassName,
  checkmarkActiveClassName,
  checkmarkActive,
  checkmarkStyle,
  errorClassname,
  label,
  disabled,
  id,
  ...inputProps
}: AbstractCheckboxProps) {
  const uniqueId = useId()
  const [error, setError] = useState<FieldErrors | null>(null)

  const processedId = `${id}_${uniqueId}`
  const cssError =
    (withError || error?.type || Object.keys(error?.types ?? {})?.length > 0) && errorClassname ? errorClassname : null

  return (
    <label data-comp="Abstracts/Checkbox" className={clsx(className, cssError, { disabled })} htmlFor={processedId}>
      <Input
        type="checkbox"
        {...inputProps}
        id={processedId}
        className={inputClassName}
        onError={(err) => {
          setError(err)
        }}
      />
      <div className={contentClassName}>
        <span className={clsx(checkmarkClassName, cssError)} {...(checkmarkStyle && { style: checkmarkStyle })}>
          <span className={clsx(checkmarkActiveClassName, cssError)}>{checkmarkActive ?? null}</span>
        </span>
        <span className={clsx(cssError, labelClassName)}>{label}</span>
      </div>
    </label>
  )
}

export { AbstractCheckbox, type AbstractCheckboxProps }
