"use client"

import { useEffect, type InputHTMLAttributes, type MutableRefObject, type TextareaHTMLAttributes } from "react"
import clsx from "clsx"
import { useFormContext, type FieldErrors } from "react-hook-form"

import type { ExplicitAny } from "~/@types/generics"
import objectFilter from "~/utils/object-filter"

import * as css from "./styles.css"

export type AbstractInputProps = Partial<{
  className: string
  textarea: boolean
  rows: number
  forwardRef: ExplicitAny
  withError: boolean
  errorClassname: string
  validate: Record<string, (val?: unknown) => boolean>
  onError: (node: FieldErrors) => void
}> &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onError" | "children"> &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onError" | "children">

function AbstractInput({
  className,
  forwardRef,
  type,
  textarea,
  rows,
  withError,
  errorClassname,
  name,
  required,
  placeholder,
  validate,
  ["aria-label"]: ariaLabel,
  ["aria-required"]: ariaRequired,
  min,
  max,
  minLength,
  maxLength,
  defaultValue,
  defaultChecked,
  onError,
  ...inputProps
}: AbstractInputProps) {
  const { register, formState } = useFormContext() || {}

  const error = name ? formState?.errors?.[name] ?? null : null

  const placeHolder = placeholder ? placeholder + (required ? " *" : "") : undefined

  const validations = {
    required,
    min,
    max,
    minLength,
    maxLength,
    defaultValue,
    defaultChecked,
    validate,
  }

  const filteredValidations = objectFilter(validations, ([, val]) => val !== undefined)
  const { ref: computedRef = null, ...props } = name ? register?.(name ?? "", filteredValidations) : {}
  const cssError =
    (withError || error?.type || (error?.types && Object.keys(error?.types)?.length > 0)) && errorClassname
      ? errorClassname
      : null

  useEffect(() => {
    onError?.(error as FieldErrors)
  }, [onError, error, cssError])

  const textareaComponent = (
    <textarea
      data-comp={"Abstracts/Input"}
      className={clsx(css.Textarea, className, cssError)}
      rows={rows}
      name={name}
      placeholder={placeHolder}
      ref={(node) => {
        computedRef?.(node)
        if (forwardRef && node) {
          ;(forwardRef as MutableRefObject<HTMLTextAreaElement>).current = node
        }
      }}
      aria-label={ariaLabel}
      aria-required={ariaRequired}
      {...inputProps}
      {...(error?.type ? { "aria-describedby": "req" } : {})}
      {...(name && register?.(name, filteredValidations))}
    />
  )

  const inputComponent = (
    <input
      data-comp={"Abstracts/Input"}
      type={type}
      name={name}
      maxLength={maxLength}
      defaultChecked={defaultChecked}
      defaultValue={defaultValue}
      ref={(node) => {
        computedRef?.(node)
        if (forwardRef && node) {
          ;(forwardRef as MutableRefObject<HTMLInputElement>).current = node
        }
      }}
      placeholder={placeHolder}
      className={clsx(css.Input, className, cssError)}
      aria-label={ariaLabel}
      aria-required={ariaRequired}
      {...inputProps}
      {...(error?.type ? { "aria-describedby": "req" } : {})}
      {...props}
      autoComplete={"off"}
    />
  )

  return textarea ? textareaComponent : inputComponent
}

export default AbstractInput
