import type { CheckboxProps } from "~/components/ui/Checkbox"
import { checkboxPresets, type CheckboxPresets } from "~/components/abstracts/Checkbox/utils/checkbox-preset"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

export default function useCheckboxPreset<T extends CheckboxProps>(
  key: CheckboxPresets | undefined,
  args: T
): Partial<T> {
  const t = useTranslate()
  const hasKey = key

  if ((hasKey && !checkboxPresets[key]) || !hasKey) {
    return args
  }

  const currentPreset = checkboxPresets?.[key]

  return {
    ...currentPreset,
    label: args?.label || t(currentPreset?.label),
    ...args,
  }
}
