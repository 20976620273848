"use client"

import clsx from "clsx"
import { FormProvider, type FieldValues, type UseFormReturn } from "react-hook-form"

import type { PropsWithClassName } from "~/@types/generics"
import RecaptchaHandler from "~/providers/RecaptchaProvider/RecaptchaHandler"

export type LayoutFormProps<T extends FieldValues> = PropsWithClassName<{
  form?: UseFormReturn<T>
  onSubmit?: () => void
}>

function LayoutForm<T extends FieldValues>({ className, children, onSubmit, form }: LayoutFormProps<T>) {
  return form ? (
    <RecaptchaHandler className={className}>
      <FormProvider {...form}>
        <form data-comp={"Abstracts/LayoutForm"} className={clsx(className)} onSubmit={onSubmit}>
          {children}
        </form>
      </FormProvider>
    </RecaptchaHandler>
  ) : (
    <form data-comp={"Abstracts/LayoutForm"} className={clsx(className)} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default LayoutForm
