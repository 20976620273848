"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import AbstractErrorList, { type AbstractErrorListProps } from "~/components/abstracts/ErrorList"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type ErrorListProps = PropsWithClassName<AbstractErrorListProps>

function ErrorList({ className, ...props }: ErrorListProps) {
  return (
    <AbstractErrorList
      className={clsx(
        sprinkles({
          textAlign: "left",
        }),
        css.ErrorList,
        className
      )}
      {...props}
    />
  )
}

export default ErrorList
