"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import ErrorList from "~/components/ui/ErrorList"
import { AbstractCheckbox, type AbstractCheckboxProps } from "~/components/abstracts/Checkbox"
import { useCheckboxPreset } from "~/components/abstracts/Checkbox/hooks"
import type { CheckboxPresets } from "~/components/abstracts/Checkbox/utils/checkbox-preset"
import Icon from "~/components/abstracts/Icon"

import * as css from "./styles.css"

export type CheckboxProps = PropsWithClassName<
  Partial<{
    inputClassName: string
    inputWrapperClassName?: string
    preset: CheckboxPresets
  }> &
    AbstractCheckboxProps
>

function Checkbox({
  className,
  checkmarkClassName,
  checkmarkActiveClassName,
  contentClassName,
  inputClassName,
  errorClassname,
  inputWrapperClassName,
  children,
  preset,
  ...rest
}: CheckboxProps) {
  const checkboxProps = useCheckboxPreset(preset, rest)
  return (
    <div className={clsx(css.CheckboxContainer, className)}>
      <div className={inputWrapperClassName}>
        <AbstractCheckbox
          className={css.Checkbox}
          inputClassName={clsx(css.input, inputClassName)}
          contentClassName={clsx(css.content, contentClassName)}
          checkmarkClassName={clsx(css.checkMark, checkmarkClassName)}
          checkmarkActiveClassName={clsx(
            css.checkMarkActive,

            checkmarkActiveClassName
          )}
          labelClassName={clsx(css.label)}
          errorClassname={clsx(css.Error, errorClassname)}
          checkmarkActive={<Icon name="Tick" direction="right" width={10} height={10} />}
          {...checkboxProps}
          label={children}
        />
      </div>
      <ErrorList name={checkboxProps.name} />
    </div>
  )
}

export default Checkbox
