import { useEffect } from "react"
import clsx from "clsx"
import { useInView } from "react-intersection-observer"

import { useGoogleReCaptcha } from "~/providers/RecaptchaProvider/use-google-recaptcha"

import * as css from "./styles.css"

export interface RecaptchaHandlerProps {
  className?: string
  children?: JSX.Element | JSX.Element[]
}

function RecaptchaHandler({ className, children }: RecaptchaHandlerProps) {
  const [inViewRef, inView] = useInView({ initialInView: false })
  const { loadRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (inView && loadRecaptcha) loadRecaptcha()
  }, [inView])

  return (
    <div ref={inViewRef} className={clsx(css.RecaptchaHandler, className)}>
      <div className={css.marker} />
      {children}
    </div>
  )
}

export default RecaptchaHandler
