import type { ErrorOption } from "react-hook-form"

import { ALERT_CODES } from "~/lib/alert"
import useCustomerErrors from "~/hooks/account/useCustomerErrors"
import { useAlerts } from "~/hooks/useAlerts"

export default function useOnMutationError(setError?: (name?: string, error?: ErrorOption) => void) {
  const triggerAlert = useAlerts()
  const onError = useCustomerErrors()

  const handleMutationError = (err: any, rest: any, callback?: (err: any, ...ars: any[]) => void) => {
    if (!(Object.keys(err)?.length > 0)) triggerAlert(ALERT_CODES.SERVER_ERROR)
    const { errsGlobals } = onError(err, setError)
    for (const [, val] of Object.entries(errsGlobals)) triggerAlert(val)
    callback?.(err, ...rest)
  }

  return handleMutationError
}
