import { env } from "~/env"
import jwtDecode from "jwt-decode"

import type { GetShopifyStorefrontClientOptions } from "@unlikelystudio/commerce-connector"

import { CHECKOUT_URL, SHOPIFY_SHOP_URL } from "~/lib/shopify/constants"
import { logger } from "~/utils/logger"

/**
 * Given a url, replace the domain name of the url with the new domain name
 * @param url - The URL of the page to be loaded.
 * @returns the url with the domain name changed.
 */
export function changeDomainUrlCheckout(url: string) {
  if (url?.indexOf(SHOPIFY_SHOP_URL) > -1) return url.replace(SHOPIFY_SHOP_URL, CHECKOUT_URL)
  else return url
}

export function getStorePublicCredentials(withCheckoutReplaceUrl = true): GetShopifyStorefrontClientOptions {
  const token = env.NEXT_PUBLIC_SHOPIFY_TOKEN

  const decodedToken = (token ? jwtDecode(token) : {}) as GetShopifyStorefrontClientOptions

  if (!decodedToken) {
    throw Error("[Shopify] Public token not found")
  }

  const storeUrl = withCheckoutReplaceUrl
    ? (changeDomainUrlCheckout(decodedToken?.storeUrl) as GetShopifyStorefrontClientOptions["storeUrl"])
    : decodedToken?.storeUrl

  if (!decodedToken?.storeUrl || !decodedToken?.storefrontAccessToken)
    logger.warn("[Shopify] Missing storefront credentials")

  return {
    ...decodedToken,
    storeUrl,
  }
}
