import { encode } from "qss"

import { UnlikelyRequestHeaders } from "@unlikelystudio/commerce-connector"

import { QUERY_METHODS } from "~/lib/api/constants"
import type { EndpointSettings, GenerateQueryParams, QueryParams } from "~/lib/api/types"
import { getStorePublicCredentials } from "~/lib/shopify/client/public"

async function generateQuery({
  action,
  payload,
  accessToken,
  queryParams = null,
  method,
  headers,
  options,
}: GenerateQueryParams) {
  const hasBody = method !== QUERY_METHODS.GET
  const shop = getStorePublicCredentials()

  const req = await fetch(`/api/${action}${queryParams ? `?${encode(queryParams)}` : ``}`, {
    headers: {
      "Content-Type": "application/json",
      ...(headers ? headers : {}),
      ...(accessToken && {
        [UnlikelyRequestHeaders.AUTHORIZATION]: accessToken,
        [UnlikelyRequestHeaders.STORE_URL]: shop?.storeUrl,
        [UnlikelyRequestHeaders.STORE_TOKEN]: shop?.storefrontAccessToken,
      }),
    },
    method,
    ...(hasBody && { body: JSON.stringify(payload) }),
    ...(options ?? {}),
  })

  const res = await req.json()

  return res
}

export async function requestNextApiEndpoint<TData extends unknown>(
  settings: EndpointSettings,
  params?: QueryParams
): Promise<TData> {
  return (await generateQuery({
    ...settings,
    ...(params ? params : {}),
  })) as TData
}
