import { createRef, useEffect, type ComponentProps } from "react"
import clsx from "clsx"
import { useHover } from "usehooks-ts"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import hexToHSL from "~/utils/hex-to-hsl"

import * as css from "./styles.css"

type ProductVariantsSelectorProps = PropsWithClassName<
  {
    active?: boolean
    disabled?: boolean
    hexa: Nullish<string>
    value?: string
    layout?: "large" | "light"
    onHover?(value: Nullish<ProductVariantsSelectorProps["value"]>): void
  } & ComponentProps<"div">
>

export function ColorSelector({
  className,
  layout = "large",
  value,
  active,
  disabled,
  hexa,
  onHover,
  ...rest
}: ProductVariantsSelectorProps) {
  const state = disabled ? "disabled" : active ? "active" : null
  const hsl = hexToHSL(hexa)
  const withBorder = hsl && hsl.l > 0.8

  const ref = createRef<HTMLDivElement>()
  const isHover = useHover(ref)

  useEffect(() => {
    if (isHover) {
      onHover?.(value)
    }
  }, [isHover, onHover, value])

  return (
    <div
      ref={ref}
      className={clsx(
        className,
        css.ColorSelector({ layout }),
        state && css.state[state],
        withBorder && css.border,
        css
      )}
      style={hexa ? { color: hexa } : undefined}
      {...rest}
    >
      <div className={clsx(css.content)} />
    </div>
  )
}
